import React from 'react'
import '../styles/institucional.scss'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout/index'
import PDF from '../../content/Carta de Intenção Site Dia Brasil Lei 14611.pdf'
import PDF_CNPJ_1 from '../../content/03.476.811.0211-59 - 28.03.pdf'
import PDF_CNPJ_2 from '../../content/03.476.811.0328-60 - 28.03.pdf'
import PDF_CNPJ_3 from '../../content/03.473.811.0001-51 - 28.03.pdf'
import PDF_CNPJ_4 from '../../content/03.476.811.0630-72 - 28.03.pdf'

// Icons
import checkIcon from '../../images/institucional/check.svg'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-lg-block d-none">
      <div className="backgroud-banner-diaxpress w-100 d-flex align-items-center">
      </div>
    </section>
    <section className="container-fluid p-0 d-lg-none d-block">
      <div className="backgroud-banner w-100"></div>
    </section>
  </>
)

const PrincipiosCards = () => (
  <>
    <section className="d-flex justify-content-center my-0 mb-5 my-md-5 p-0">
      <div className="card-grid-equidade">
        <a href={PDF} target="_blank" rel="noopener noreferrer">
          <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto" /><p className='font-size-1'>Relatório de Transparência Salarial - DIA Brasil</p></div>
        </a>
      </div>
    </section>
  </>
)

const Relatorios = () => (
  <>
    <section className="d-flex justify-content-center my-0 mb-5 my-md-5 p-0">
      <div className="card-grid-equidade-relatorios">
        <a href={PDF_CNPJ_1} target="_blank" rel="noopener noreferrer">
          <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto" /><p className='font-size-1'>CNPJ: 03.476.811.0211-59<br />DIA BRASIL LTDA</p></div>
        </a>
        <a href={PDF_CNPJ_2} target="_blank" rel="noopener noreferrer">
          <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto" /><p className='font-size-1'>CNPJ: 03.476.811.0328-60<br />DIA BRASIL LTDA</p></div>
        </a>
        <a href={PDF_CNPJ_3} target="_blank" rel="noopener noreferrer">
          <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto" /><p className='font-size-1'>CNPJ: 03.473.811.0001-51<br />DIA BRASIL LTDA</p></div>
        </a>
        <a href={PDF_CNPJ_4} target="_blank" rel="noopener noreferrer">
          <div className="card card-principios border-0"><img src={checkIcon} alt="icone de correto" /><p className='font-size-1'>CNPJ: 03.476.811.0630-72<br />DIA BRASIL LTDA</p></div>
        </a>
      </div>
    </section>
  </>
)

const Recuperacaojudicial = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      // { path: '/institucional/', name: 'Institucional' },
      { path: '/institucional/relatorio-transparencia-salarial/', name: 'Relatório de Transparência Salarial' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Recuperação Judicial - Dia Supermercados</title>
        <meta name="description" content="Recuperação Judicial" />
        <meta name="keywords" content="dia supermercado, Recuperação Judicial" />
        <meta name="author" content="DIA Supermercados" />
        <meta
          property="og:image"
          content='https://www.dia.com.br/card-dia.png'
        />
      </Helmet>
      <Banner />
      <section className="container-md d-flex flex-column align-items-center principios p-0 mb-2">
        <h2 className="mb-4 text-center" id="one" tabIndex={0} >Relatório de Transparência Salarial - DIA Brasil <br /> Lei 14.611</h2>
      </section>
      <PrincipiosCards />
      <section className="container d-flex flex-column align-items-center principios p-0 mb-2">
        <h2 className="mb-4 text-center" id="one" tabIndex={0} >Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024</h2>
      </section>
      <Relatorios />
    </Layout>
  )
}
export default Recuperacaojudicial
